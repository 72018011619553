<template>
    <TestimonialContentSlider
        v-editable="blok"
        :data="testimonialContentSlider"
    />
</template>

<script setup lang="ts">
import TestimonialContentSliderType from '@types/TestimonialContentSliderType';
import TestimonialCardType from '@types/TestimonialCardType';
import TestimonialContentSlider from '~/components/page-building/testimonial-content-slider/TestimonialContentSlider.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const testimonialContentSlider = computed<TestimonialContentSliderType>(() => {
    const testimonials = props.blok.testimonials
        ? props.blok.testimonials.map((testimonial: any) => {
              return {
                  name: testimonial.content.name,
                  businessName: testimonial.content.business_name,
                  testimonial: testimonial.content.testimonial,
                  vEditable: testimonial,
              } as TestimonialCardType;
          })
        : [];

    return {
        heading: props.blok.heading,
        content: richTextToHtml(props.blok.content) as string,
        testimonials,
        reverseLayout: props.blok.reverse_layout,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        vEditable: props.blok,
    };
});
</script>
